import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import PauseCircleOutlineOutlined from '@mui/icons-material/PauseCircleOutlineOutlined';
import PlayCircleOutlineOutlined from '@mui/icons-material/PlayCircleOutlineOutlined';
import { TeamTimeoutsType } from '@/service/types';
import { FixtureTimeouts } from '@/components/FixtureScore/FixtureTimeouts';
import { FixtureScoreTeamBox } from '@/components/FixtureScore/FixtureScoreTeamBox';
import { FixtureScoreInfoTeam } from './FixtureScoreStyledComponents';

type StateWithTimeoutInfo = {
  totalTimeoutsPerTeam: TeamTimeoutsType;
  awayTeamTimeoutsLeft: TeamTimeoutsType;
  homeTeamTimeoutsLeft: TeamTimeoutsType;
  currentTeamInPlay: string;
};

interface TeamScore {
  id: string;
  name: string;
  color: string;
  score: number;
}

interface FixtureScoreProps<TimeoutState extends StateWithTimeoutInfo> {
  homeTeam: TeamScore;
  awayTeam: TeamScore;
  stateWithTimeoutInfo?: TimeoutState;
  currentClock: string;
  isClockPaused: boolean;
}

const LABELS = {
  HOME_TIMEOUTS: 'home-timeouts',
  AWAY_TIMEOUTS: 'away-timeouts',
  HOME_SCORE_TEAMBOX: 'score-home-color-teambox',
  AWAY_SCORE_TEAMBOX: 'score-away-color-teambox',
  CURRENT_CLOCK_TIME: 'current-clock-time',
  SCORE_CONTAINER: 'score-container',
  FIXTURE_TEAMS_SCORE: 'fixture-teams-score',
};

export const FixtureScore: FC<FixtureScoreProps<StateWithTimeoutInfo>> = ({
  homeTeam,
  awayTeam,
  stateWithTimeoutInfo,
  currentClock,
  isClockPaused,
}) => {
  const teamsInPlay = {
    homeTeam: stateWithTimeoutInfo?.currentTeamInPlay === homeTeam.id,
    awayTeam: stateWithTimeoutInfo?.currentTeamInPlay === awayTeam.id,
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      display='grid'
      alignItems={isMobile ? 'center' : 'start'}
      justifyContent='center'
      maxWidth='100%'
      gridTemplateColumns='1fr max-content 1fr'
      gap={2}
    >
      <Stack gap={1} alignItems='end'>
        <FixtureScoreInfoTeam aria-label={LABELS.HOME_SCORE_TEAMBOX}>
          {homeTeam.name}
          <FixtureScoreTeamBox
            teamColor={homeTeam.color}
            inPlay={teamsInPlay.homeTeam}
            homeTeam
          />
        </FixtureScoreInfoTeam>
        {stateWithTimeoutInfo &&
          !!stateWithTimeoutInfo.totalTimeoutsPerTeam && (
            <FixtureTimeouts
              ariaLabel={LABELS.HOME_TIMEOUTS}
              totalTimeouts={stateWithTimeoutInfo.totalTimeoutsPerTeam}
              teamTimeoutsLeft={stateWithTimeoutInfo.homeTeamTimeoutsLeft}
              teamColor={homeTeam.color}
              homeTeam
            />
          )}
      </Stack>

      <Stack gap={0.5} aria-label={LABELS.SCORE_CONTAINER}>
        <Typography
          aria-label={LABELS.FIXTURE_TEAMS_SCORE}
          align='center'
          fontWeight={700}
          lineHeight={1}
          fontSize='1.5rem'
        >
          {homeTeam.score} - {awayTeam.score}
        </Typography>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'
          justifyContent='center'
        >
          <Typography
            aria-label={LABELS.CURRENT_CLOCK_TIME}
            fontSize='1.3rem'
            lineHeight={1.2}
            fontWeight={700}
          >
            {currentClock}
          </Typography>

          {isClockPaused ? (
            <PauseCircleOutlineOutlined />
          ) : (
            <PlayCircleOutlineOutlined />
          )}
        </Stack>
      </Stack>

      <Stack gap={1}>
        <FixtureScoreInfoTeam
          aria-label={LABELS.AWAY_SCORE_TEAMBOX}
          justifySelf='start'
        >
          <FixtureScoreTeamBox
            teamColor={awayTeam.color}
            inPlay={teamsInPlay.awayTeam}
          />
          {awayTeam.name}
        </FixtureScoreInfoTeam>
        {stateWithTimeoutInfo &&
          !!stateWithTimeoutInfo.totalTimeoutsPerTeam && (
            <FixtureTimeouts
              ariaLabel={LABELS.AWAY_TIMEOUTS}
              totalTimeouts={stateWithTimeoutInfo.totalTimeoutsPerTeam}
              teamTimeoutsLeft={stateWithTimeoutInfo.awayTeamTimeoutsLeft}
              teamColor={awayTeam.color}
            />
          )}
      </Stack>
    </Box>
  );
};
