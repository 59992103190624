import { FC } from 'react';
import Badge from '@mui/material/Badge';
import { Typography } from '@mui/material';
import { MatchScore } from '@/service/types';
import { NotchedBox } from '@/components/common/NotchedBox';
import { getAggregateForPeriod } from './PeriodsScores';

interface PeriodScoreProps {
  periodNumber: number;
  periodScore: MatchScore;
  periodAggregate?: ReturnType<typeof getAggregateForPeriod>;
}
const BORDER_WIDTH = '1px';
export const PeriodScore: FC<PeriodScoreProps> = ({
  periodNumber,
  periodScore,
  periodAggregate,
}) => {
  return (
    <Badge
      aria-label='fixture-period-state'
      badgeContent={periodNumber}
      sx={{
        fontSize: '0.875rem',
        marginRight: `-${BORDER_WIDTH}`,
        fontWeight: 500,
        color: ({ typography }) => typography.caption.color,
        '& .MuiBadge-badge': {
          right: '50%',
          background: 'transparent',
        },
        '&:last-of-type': {
          color: 'currentcolor',
        },
      }}
    >
      <NotchedBox
        sx={{
          px: 1,
          pt: 0.5,
          pb: 0.5,
          border: `${BORDER_WIDTH} solid currentcolor`,
          textAlign: 'center',
        }}
      >
        <Typography fontSize='1em'>
          {`${periodScore.homeTeamScore} - ${periodScore.awayTeamScore}`}
        </Typography>
        {periodAggregate && (
          <Typography fontSize='0.8em'>
            {`${periodAggregate.homeTeam} - ${periodAggregate.awayTeam}`}
          </Typography>
        )}
      </NotchedBox>
    </Badge>
  );
};
