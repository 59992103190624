import useSWR from 'swr';
import { FixtureStream } from '@/service/types';
import {
  SERVICE_ENDPOINT,
  fetchFromMTService,
} from '../fetcher/monitoringToolService';
import { HTTP_STATUS_CODE } from '../constants';
import { ApiError } from '../ApiError';
import { UseFixtureConfigParams } from './useFixtureConfig';

interface UseStreamingProps extends UseFixtureConfigParams {
  isStreamSport: boolean;
}
export const useStreaming = ({
  token,
  fixtureId,
  isStreamSport,
}: UseStreamingProps) => {
  const { data, error } = useSWR<FixtureStream, ApiError>(
    token && fixtureId && isStreamSport
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.Streaming(fixtureId).streaming.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
    },
  );

  return {
    liveStreamUrl: data?.liveStream,
    archivedStreamUrl: data?.archivedStream,
    streamStartDateTime: data?.streamStartDateTime,
    error,
    isLoading: !data && !error,
  };
};
