import FilterList from '@mui/icons-material/FilterList';
import FilterListOff from '@mui/icons-material/FilterListOff';
import Undo from '@mui/icons-material/Undo';
import { Tooltip, IconButton } from '@mui/material';
import { FC, useContext } from 'react';
import { PANEL } from '@/contexts/UIState/types';
import { DICTIONARY } from '@/constants/dictionary';
import { KeyboardButton } from '@/components/common/KeyboardButton';
import { UIStateContext } from '@/contexts/UIState/context';
import { FilterButtonsProps } from '@/components/ActionsTable/ActionBar/FilterButtons';
import { FixturesTableContext } from '@/components/FixturesTable/context/FixturesTableContext';

export const FixturesFilterButtons: FC<Partial<FilterButtonsProps>> = ({
  size = 'small',
}) => {
  const { setOpenedPanel } = useContext(UIStateContext);
  const {
    filters: { filtersHistory, clearFilters, undoFilters, filtersList },
  } = useContext(FixturesTableContext);

  return (
    <>
      {!!filtersList.length && (
        <Tooltip arrow title={DICTIONARY.COMMON.CLEAR_FILTERS}>
          <IconButton color='primary' onClick={clearFilters}>
            <FilterListOff fontSize={size} />
          </IconButton>
        </Tooltip>
      )}
      {!!filtersHistory.length && (
        <Tooltip arrow title={DICTIONARY.COMMON.UNDO_FILTER}>
          <IconButton color='primary' onClick={undoFilters}>
            <Undo fontSize={size} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip
        arrow
        title={
          <>
            {DICTIONARY.COMMON.FILTERS}
            <KeyboardButton theKey='f' />
          </>
        }
      >
        <IconButton
          color='primary'
          onClick={() => setOpenedPanel(PANEL.FILTERS)}
        >
          <FilterList fontSize={size} />
        </IconButton>
      </Tooltip>
    </>
  );
};
