import { Button, Stack, Typography, useTheme } from '@mui/material';
import { COMMON_STRING } from '@/constants/dictionary';

interface ConfirmationButtonSetProps {
  onConfirm: VoidFunction;
  onClose: VoidFunction;
  confirmMessage: string;
  shouldTitle?: boolean;
  disabled?: boolean;
}

export const ConfirmationButtonSet = ({
  onConfirm,
  onClose,
  confirmMessage,
  shouldTitle = true,
  disabled,
}: ConfirmationButtonSetProps) => {
  const theme = useTheme();
  return (
    <Stack maxWidth={370} gap={1} p={shouldTitle ? 1 : 0}>
      {shouldTitle && (
        <Typography
          color={theme.palette.getContrastText(theme.palette.background.paper)}
        >
          {COMMON_STRING.CONFIRM}
        </Typography>
      )}
      <Typography
        color={theme.palette.getContrastText(theme.palette.background.paper)}
      >
        {confirmMessage}
      </Typography>
      <Stack flexDirection='row' justifyContent='space-between' gap={1}>
        <Button size='small' onClick={onClose}>
          {COMMON_STRING.CANCEL}
        </Button>
        <Button
          disabled={disabled}
          size='small'
          variant='contained'
          onClick={onConfirm}
          data-testid='confirm-button-test-id'
        >
          {COMMON_STRING.CONFIRM}
        </Button>
      </Stack>
    </Stack>
  );
};
