import { FIXTURE_ACTION_TYPE, SPORT_ID } from '@/service/constants';
import { FixtureAction, FixtureConfig } from '@/service/types';

interface GetOptionalPeriodNameProps {
  period: number;
  fixtureConfig: FixtureConfig | undefined;
  fixtureSeqNum: number;
  actions?: FixtureAction[];
}

interface GetPeriodNameProps extends GetOptionalPeriodNameProps {
  sportId?: number;
}

export const getPeriodName = ({
  period,
  fixtureConfig,
  sportId,
  fixtureSeqNum,
  actions,
}: GetPeriodNameProps) => {
  if (sportId !== SPORT_ID.SOCCER) return period.toString();

  return getOptionalPeriodName({
    period,
    fixtureConfig,
    fixtureSeqNum,
    actions,
  });
};

export const getOptionalPeriodName = ({
  period,
  fixtureConfig,
  fixtureSeqNum,
  actions,
}: GetOptionalPeriodNameProps) => {
  const optionalPeriodNum =
    actions?.find(
      (action) =>
        action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.END_PERIOD &&
        action.fixtureSeqNum < fixtureSeqNum &&
        action.metadata?.requestedOptionalPeriodSequenceNumber,
    )?.metadata.requestedOptionalPeriodSequenceNumber || period;

  return (
    fixtureConfig?.fixtureOptions.sportRules.periodDetails.find(({ seq }) =>
      optionalPeriodNum >= period ? seq === optionalPeriodNum : seq === period,
    )?.label || period.toString()
  );
};
