import { ALL_SWITCH } from '@/components/Market';
import { FIXTURE_ACTION_FLAG_STATE } from '@/service/constants';

export const COMMON_STRING = {
  SELECTED: 'Selected',
  PREVIOUS_INCIDENT_RESOLVER: 'Previous incident resolver',
  GO_TO_COLLECTION: 'Go to Collection',
  DESELECT_ALL: 'Deselect all',
  ASSIGN_ME: 'Assign me',
  UNASSIGN_ME: 'Unassign me',
  LOGOUT: 'Logout',
  SHORTCUTS: 'Shortcuts',
  DETAILS: 'Details',
  KEYBOARD_SHORTCUTS: 'Keyboard shortcuts',
  CHECKLIST: 'Checklist',
  FIXTURE_INFO: 'Fixture Info',
  DATA_COLLECTORS: 'Data Collectors',
  SCOREBOARD: 'Scoreboard',
  NOTIFICATIONS: 'Notifications',
  INCIDENT_RESOLVER: 'Incident Resolver',
  RESOULTION_TIME: 'Resolution Time',
  TIME_SINCE_REPORTED: 'Time since reported',
  USER_AVATAR_TOOLTIP: 'Settings',
  FIXTURE_ID_PLACEHOLDER: 'Fixture ID must be in UUID format',
  FILTER_BY: 'Filter by',
  ADD_FILTER: 'Add filter',
  CLEAR_FILTERS: 'Clear filters',
  UNDO_FILTER: 'Undo filter',
  FILTERS: 'Filters',
  EXPORT_XLSX: 'Export XLSX',
  EXPORT_CSV: 'Export CSV',
  COPY: 'Copy',
  SHOW: 'Show',
  PLAYER: 'Player',
  PLAYERS: 'Players',
  LATENCY: 'Latency',
  MISTAKE: 'Mistake',
  ACTION_COMMENT: 'Action comment',
  ACTION_TYPE: 'Action Type',
  SEND_TYPE: 'Send Type',
  COMMENT: 'Comment',
  ADD_COMMENT: 'Add Comment',
  HOME: 'Home',
  FIXTURE_ID: 'Fixture ID',
  EDIT_ACTION: 'Edit Action',
  DATA_REVIEW: 'Data review',
  DELETE_ACTION: 'Delete Action',
  RESTORE_ACTION: 'Restore Action',
  ACTION_EVALUATION: 'Action Evaluation',
  PREFERENCES: 'Preferences',
  SUPER_ADMIN_VIEW: 'Superadmin view',
  THEME: 'THEME',
  ASSIGNED: 'Assigned',
  FLAG: {
    [FIXTURE_ACTION_FLAG_STATE.UNCONFIRMEND]: 'Confirm flag',
    [FIXTURE_ACTION_FLAG_STATE.CONFIRMED]: 'Unconfirm flag',
  },
  DEVICE_LOCATION: 'Device Location',
  COURT_POSITION: 'Court Position',
  APPLY: 'Apply',
  REMOVE: 'Remove',
  ADD: 'Add',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  GO_TO_EXTERNAL_STATISTICS: 'Go To External Statistics',
  GO_TO_TOP: 'Go To Top',
  REFRESH: 'Refresh',
  STATS: {
    INTERNAL_HOME: 'Internal Home',
    INTERNAL_AWAY: 'Internal Away',
    EXTERNAL_HOME: 'External Home',
    EXTERNAL_AWAY: 'External Away',
  },
  SUBMIT: 'Submit',
  SELECT_ALL: 'Select All',
  NONE: 'None',
  ACTION_JSON: 'Action JSON',
  CLOSE: 'Close',
  OVERWRITE: 'Overwrite',
  UPDATE: 'Update',
  SYNC: 'Sync',
  OK: 'OK',
  NAME: 'Name',
  SURNAME: 'Surname',
  LAST_5_RATINGS: 'Last 5 ratings',
  COMPLETED_MATCHES: 'Completed matches',
  COMPETITION: 'Competition',
  UNASSIGNED: 'Unassigned',
  LAST_MATCH_DATE: 'Last match date',
  FLAG_ON_FIELD: 'Flag on field',
  NO_VAR: 'NO VAR',
  POSSIBLE_VAR_ACTIVATED: 'Possible VAR activated',
  CONFIRM: 'Confirm',
  GAME_STATE: 'Game state',
  KICKOFF: 'Kickoff',
  PAT: 'PAT',
  REPORT_GENERATED: 'Report generated',
  DOWNLOAD: 'Download',
  OPEN_IN_A_NEW_TAB: 'Open in a new tab',
  SUPERVISOR_NAME: 'Supervisor Name',
  SCORER_NAME: 'Scorer name',
  APP_VERSION: 'App version',
  RANK: 'Rank',
  WORKSTATION: 'Workstation',
  TOTALS: 'Totals',
  COACH: 'Team/Coach',
  TEAM: 'Team',
  ALL_QUARTERS: 'All quarters',
  DID_NOT_PARTICIPATED: 'DNP',
  LIVE_STREAM: 'Live Stream',
  PICTURE_IN_PICTURE_MODE_IS_OPEN: 'Picture-in-Picture mode is open',
  OPEN_PICTURE_IN_PICTURE_MODE: 'Open in Picture-in-Picture mode',
  YES: 'Yes',
  NO: 'No',
  CALL_TO: 'Call to',
  ADD_RATING: 'ADD RATING',
  EXTERNAL_STATS: 'External Stats',
  DATA_COLLECTORS_EVALUATION: 'Data Collectors Evaluation',
  SLA_BREACH_FIXTURE_EVALUATION: 'SLA Breach Fixture Evaluation',
  SLA_BREACH_ACTION_EVALUATION: 'SLA Breach Action Evaluation',
  ACCESS_DENIED_TEXT:
    'You do not have access to this page. Please contact administrator for more information.',
  ARE_YOU_SURE_YOU_WANT_TO_OPEN_MARKET: (open: boolean) =>
    `Are you sure you want to ${open ? 'close' : 'open'} the market?`,
  CONFIRM_CLOSE_MARKET: (open: boolean) =>
    `Confirm ${open ? 'close' : 'open'} market`,
  OPENED: 'OPENED',
  CLOSED: 'CLOSED',
  UNDERWAY: 'UNDERWAY',
  DATA_POINTS: 'Data Points',
  OVER: 'OVER',
  DATA_REVIEW_OF_ACTION_TYPES: 'Data review of action types',
  ALL_MARKETS: 'All Markets',
  MARKET_OPENED: 'Market has been opened.',
  MARKET_CLOSED: 'Market has been closed.',
  VAR_UNDERWAY: 'VAR Underway',
  VAR_OVER: 'VAR Over',
  VIDEO_CHECK_RESULT: 'Video Check Result',
  VOLUME: 'Volume',
  TIMELINE: 'Timeline',
  PLAY: 'Play',
  PAUSE: 'Pause',
  PERIOD: 'Period',
  REPLAY: 'Replay',
  REPLAY_TEN_SECONDS: 'Replay 10s',
  FORWARD_TEN_SECONDS: 'Forward 10s',
  ADVANCED: 'Advanced',
  BASKETBALL: 'Basketball',
  SCORESHEET: 'Scoresheet',
  AMERICAN_FOOTBAL: 'American Football',
  EXTERNAL: 'External',
  MATCH_STARTING_IN: 'Match starting in:',
  MATCH_RESUMING_IN: 'Match resuming in:',
  MARKET_IS_CLOSED: 'Market is closed!',
  DATA_UNDER_REVIEW: 'Data under review!',
  PLAY_STOPPED: 'Play stopped',
  PLAY_RESUMED: 'PLAY RESUMED',
  ALL: 'All',
  ONLY_NOT_UPDATED: 'Only not updated',
  SOON: 'SOON',
  NOT_AVAILABLE: 'N/A',
  ACTION: 'Action',
  ACTIONS: 'Actions',
  ACTION_ID: 'Action ID',
  SEARCH: 'Search',
  SEARCH_ACTIONS: 'Search Actions',
  ACTION_TYPES: 'Action Types',
  NEW_TEMPLATE: 'New Template',
  FILTER_TEMPLATE_ADD_TOOLTIP:
    'Apply selected filters to currently active template',
  RESET_TEMPLATE_TOOLTIP: 'Reset current template',
  PROCEED: 'Proceed',
  DONT_SHOW_AGAIN: 'Do not show again',
  ADD_TEMPLATE: '+ Add template',
  OVERRIDE_TEMPLATE: 'Override template',
  OVERRIDE_TEMPLATE_BODY:
    'You are about to override selected template with currently active filters. Are you sure you want to proceed?',
  CHANGING_TEMPLATE: 'Changing template',
  CHANGING_TEMPLATE_BODY:
    'You are about to change the filter template and all currently applied temporary filters will be lost. Are you sure you want to proceed?',
  TEMPLATE_DELETE_CONFIRM:
    'Are you sure you want to delete this filter template?',
  NO_TEMPLATE: 'No Template',
  BASIC_TABLE: 'Basic Table',
  MAC: 'Mac',
  WINDOWS: 'Windows',
  LINUX: 'Linux',
  UNKNOWN: 'Unknown',
  CTRL_KEY: 'CTRL',
  PLAYER_RETURNED: 'PLAYER RETURNED',
  BALL_POSSESSION: 'Ball possession',
  MIN: 'MIN',
  SEC: 'SEC',
  NOT_IMPLEMENTED_YET: 'Not yet implemented',
  COMPARISON_COMMENT_TOOLTIP_TITLE: 'Copy all SLA breach action evaluations',
  COLLECTION_ID_COPY_TOOLTIP: 'Copy collection ID',
  GO_TO_CONSOLE_TOOLTIP: 'Go to Console',
  CONFIRM_ALL_SWITCH_ACTION: (option?: ALL_SWITCH) =>
    `Are you sure you want to switch all data points to review ${option}?`,
  CONFIRM_DATA_POINTS_TITLE: (option?: ALL_SWITCH) =>
    `Confirm data points to ${option}`,
  ADVANCED_STATS_DOES_NOT_EXIST: `Advanced stats data doesn't exist...`,
  TOTAL: 'Total',
  SLA: 'SLA',
  MATCH: 'MATCH',
  REASON: 'Reason',
  SCORER: 'Scorer',
  SCORERS: 'Scorers',
  SUPERVISOR: 'Supervisor',
  DATA_COLLECTOR: 'Data Collector',
  FIXTURES_WITH_INCIDENTS: 'Fixtures with incidents',
  EXTEND_SESSION: 'Extend session',
  YOUR_SESSION_WILL_EXPIRE_IN: 'Your session will expire in',
  YOUR_SESSION_HAS_EXPIRED: 'Your session has expired. Please login again.',
  SESSION_EXPIRED: 'Session expired',
} as const;

export const SUCCESS_STRING = {
  COPIED_TO_CLIPBOARD: 'Copied to clipboard',
  FLAG_UPDATED: 'Flag updated',
  SUPERVISOR_CALL_SUCCESSFULLY_ACKNOWLEDGED:
    'Supervisor call successfully acknowledged',
  ACTION_SUCCESSFULLY_ADDED: 'Action successfully added',
  ACTION_SUCCESSFULLY_DELETED: 'Action successfully deleted',
  ACTION_SUCCESSFULLY_RESTORED: 'Action successfully restored',
  COMPARISON_DATA_SUCCESSFULLY_UPDATED: 'Comparison data successfully updated',
  RATING_SUCCESSFULLY_REMOVED: 'Rating successfully removed',
  RATING_SUCCESSFULLY_RATED: 'Rating successfully rated',
  RATING_SUCCESSFULLY_SAVED: 'Rating successfully saved',
  SUCCESSFULLY_ASSIGNED_TO_ISSUES: 'Successfully assigned to issues',
  SUCCESSFULLY_UNASSIGNED_FROM_ISSUES: 'Successfully unassigned from issues',
};

export const DELETE_CONFIRMATION_STRING = {
  COMMENT: 'Are you sure you want to remove this comment?',
  ACTION: 'Are you sure you want to delete this action?',
  LATENCY: 'Are you sure you want to delete this latency',
};

export const RESTORE_CONFIRMATION_STRING = {
  ACTION: 'Are you sure you want to restore this action?',
};
export const ERROR_STRING = {
  FIXTURE_ID_MISSING: 'Missing fixture id',
  FEATURE_NOT_SUPPORTED: 'Sorry, this is not yet supported',
  INCORRECT_FIXTURE: 'Fixture ID format is incorrect',
} as const;

export const PREFERENCES_STRING = {
  SAVE_USER_DEFAULT: 'Save as My Default',
  APPLY_USER_DEFAULT: 'Apply My Default',
  APPLY_APP_DEFAULT: 'Apply App Default',
};

export const TABLE_SORT_ORDER = {
  DESC: 'desc',
  ASC: 'asc',
} as const;

export const DICTIONARY = {
  COMMON: COMMON_STRING,
  ERROR: ERROR_STRING,
  SUCCESS: SUCCESS_STRING,
  PREFERENCES: PREFERENCES_STRING,
  DELETE: DELETE_CONFIRMATION_STRING,
  RESTORE: RESTORE_CONFIRMATION_STRING,
} as const;
